<!--
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-08-29 22:14:30
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-08-29 22:36:53
 * @FilePath: \settlement-frontend\src\components\layout\EmptyData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="empty-data">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" fill="white" />
      <path
        d="M16.9988 22H46.9988L52.9988 43.5H11.2656L16.9988 22Z"
        fill="#ACB0C6"
      />
      <path d="M54 48L47 22V16L54 38V48Z" fill="#9397B2" />
      <path d="M10 48L17 22V16L10 38V48Z" fill="#9397B2" />
      <path
        d="M17 16C17 14.8954 17.8954 14 19 14H45C46.1046 14 47 14.8954 47 16V22H17V16Z"
        fill="#BCC1CF"
      />
      <rect x="10" y="36" width="44" height="14" rx="2" fill="#D8DAE0" />
      <rect x="26" y="34" width="12" height="6" rx="2" fill="white" />
    </svg>
    <p>{{ $t("common.noData") }}</p>
  </div>
</template>
<style lang="scss" scoped>
.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  p {
    font-size: 14px;
    font-weight: 500;
    color: #a1a5b7;
  }
}
</style>
