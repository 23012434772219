
import { defineComponent, ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { ApiBase } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { useVModel } from "@vueuse/core";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2";

export default defineComponent({
  name: "kyc-common-address",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    updateSection: {
      type: String,
      default: "identification",
    },
    streetLabel: {
      type: String,
      default: () => useI18n().t("kyc.address.street"),
    },
    houseNoLabel: {
      type: String,
      default: () => useI18n().t("kyc.address.houseNo"),
    },
    postalCodeLabel: {
      type: String,
      default: () => useI18n().t("kyc.address.postalCode"),
    },
    provinceLabel: {
      type: String,
      default: () => useI18n().t("kyc.address.province"),
    },
    cityLabel: {
      type: String,
      default: () => useI18n().t("kyc.address.city"),
    },
    districtLabel: {
      type: String,
      default: () => useI18n().t("kyc.address.district"),
    },
    countryLabel: {
      type: String,
      default: () => useI18n().t("kyc.address.country"),
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const model = useVModel(props, "modelValue", emit);
    const { t } = useI18n();

    const country = ref("");

    const { showServerErrorMsg } = mixin();

    const countryLoading = ref(false);
    const countryOptions = ref<TaggingItem[]>([]);
    const provinceLoading = ref(false);
    const provinceOptions = ref<TaggingItem[]>([]);
    const cityLoading = ref(false);
    const cityOptions = ref<TaggingItem[]>([]);
    const districtLoading = ref(false);
    const districtOptions = ref<TaggingItem[]>([]);

    watch(
      () => model.value.country_code,
      (newVal) => {
        if (newVal) {
          country.value = newVal;
          if (newVal == "CN" || newVal == "TW") {
            getProvinceData();
          } else if (newVal == "HK") {
            getCityData("ho");
          } else if (newVal == "MO") {
            getCityData("ma");
          } else {
            if (model.value.city) {
              getCityData(model.value.city);
            }
          }
        }
      }
    );

    watch(
      () => model.value.province_id,
      (newVal) => {
        if (newVal) {
          getChineseCityData();
        }
      }
    );

    watch(
      () => model.value.city_id,
      (newVal) => {
        if (newVal) {
          if (country.value == "CN") getDistrictData();
        }
      }
    );

    const getCountryData = async () => {
      countryLoading.value = true;
      const { data } = await ApiBase.getCountryData();
      countryLoading.value = false;
      if (data.code == 0) {
        countryOptions.value.splice(
          0,
          countryOptions.value.length,
          ...data.data
        );
      }
    };

    const getCityData = async (query) => {
      if (country.value) {
        if (query) {
          cityLoading.value = true;
          const { data } = await ApiBase.getCityDataByCountry({
            country: country.value,
            search_value: query,
          });
          cityLoading.value = false;
          if (data.code == 0) {
            cityOptions.value = data.data;
          } else {
            showServerErrorMsg(data);
          }
        }
      } else {
        Swal.fire({
          title: t("kyc.address.tips"),
          icon: "info",
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const debounceCityDataSearch = _.debounce(getCityData, 1000);

    const searchCityData = (query: string) => {
      debounceCityDataSearch(query);
    };

    const getProvinceData = async () => {
      provinceLoading.value = true;
      await getProvinceCityDistrictById(1);
      provinceLoading.value = false;
    };

    const getChineseCityData = async () => {
      cityLoading.value = true;
      await getProvinceCityDistrictById(2);
      cityLoading.value = false;
    };

    const getDistrictData = async () => {
      if (country.value === "CN") {
        districtLoading.value = true;
        await getProvinceCityDistrictById(3);
        districtLoading.value = false;
      }
    };

    const getProvinceCityDistrictById = async (level) => {
      let object_id = 0;
      if (level === 1) {
        object_id = 0;
      } else if (level === 2) {
        object_id = model.value.province_id;
      } else if (level === 3) {
        object_id = model.value.city_id;
      }
      const { data } = await ApiBase.getProvinceCityDistrictById({
        country: country.value,
        level: level,
        object_id: object_id,
      });
      if (data.code === 0) {
        if (level === 1) {
          provinceOptions.value.splice(
            0,
            provinceOptions.value.length,
            ...data.data
          );
        } else if (level === 2) {
          cityOptions.value.splice(0, cityOptions.value.length, ...data.data);
        } else if (level === 3) {
          districtOptions.value.splice(
            0,
            districtOptions.value.length,
            ...data.data
          );
        }
      } else {
        showServerErrorMsg(data);
      }
    };

    const countryChange = (val) => {
      country.value = val;
      model.value.province_id = "";
      model.value.city_id = "";
      model.value.district_id = "";
      cityOptions.value = [];
      districtOptions.value = [];
      if (country.value == "CN" || country.value == "TW") {
        getProvinceData();
      } else if (country.value == "HK") {
        getCityData("ho");
      } else if (country.value == "MO") {
        getCityData("ma");
      }
    };

    const provinceChange = (val) => {
      model.value.city_id = "";
      model.value.district_id = "";
      districtOptions.value = [];
      getChineseCityData();
    };

    const cityChange = (val) => {
      model.value.district_id = "";
      getDistrictData();
    };

    onMounted(() => {
      getCountryData();
    });

    return {
      model,
      t,
      country,
      countryLoading,
      countryOptions,
      provinceLoading,
      provinceOptions,
      cityLoading,
      cityOptions,
      districtLoading,
      districtOptions,
      countryChange,
      provinceChange,
      cityChange,
      searchCityData,
    };
  },
});
