
import {
  defineComponent,
  ref,
  PropType,
  onMounted,
  computed,
  defineExpose,
  watch,
  reactive,
} from "vue";
import { useI18n } from "vue-i18n";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import { NumberOrString } from "@/core/directive/type/common";
import mixin from "@/mixins";
import _ from "lodash";
import { KycType } from "@/core/directive/type/kyc";
import { ContactFormData } from "@/core/directive/interface/kyc";
import { getKycCommonTypeParams } from "@/core/directive/function/kyc";
import { ApiBase } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import KycAddress from "@/components/page/kyc/common/Address.vue";
import AdditionalSection from "@/components/page/kyc/common/AdditionalSection.vue";
import { object } from "yup";

export default defineComponent({
  name: "contact-edit-modal",
  components: {
    KycAddress,
    AdditionalSection,
  },
  props: {
    id: {
      type: Number as PropType<NumberOrString>,
      default: 0,
    },
    updateSection: {
      type: String,
      default: "identification", // identification | additional
    },
    type: {
      type: String as PropType<KycType>,
      default: KycType.Juridical,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-info"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const localLoading = ref(false);
    const modalRef = ref<null | HTMLElement>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const {
      showValidateErrorMsg,
      showServerErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const isEditModal = computed(() => {
      return props.id !== 0;
    });

    const formData = ref(Object.assign({}, _.cloneDeep(ContactFormData)));
    const juridicalRequiredField = {
      company_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    };
    const naturalRequiredField = {
      lastname: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      firstname: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      area_code: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    };
    const rules = reactive({
      identification: {
        address1: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        address2: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        postcode: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        city_id: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        country_code: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: false,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        district_id: [
          {
            required: false,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
      },
    });

    watch(
      () => props.type,
      (newVal) => {
        if (newVal === KycType.Juridical) {
          Object.assign(rules.identification, juridicalRequiredField);
        } else if (newVal === KycType.Natural) {
          Object.assign(rules.identification, naturalRequiredField);
        }
      },
      { immediate: true }
    );

    watch(
      () => formData.value.identification.country_code,
      (newVal) => {
        if (newVal == "CN") {
          rules.identification.province_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        } else if (newVal == "HK" || newVal == "MO") {
          rules.identification.province_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        } else if (newVal == "TW") {
          rules.identification.province_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        }
      }
    );

    const areaCodesLoading = ref(false);
    const areaCodesOptions = ref<TaggingItem[]>([]);

    const getAreaCodesData = async () => {
      areaCodesLoading.value = true;
      const { data } = await ApiBase.getAreaCodesData({
        max_item: "all",
      });
      areaCodesLoading.value = false;
      if (data.code === 0) {
        areaCodesOptions.value.splice(
          0,
          areaCodesOptions.value.length,
          ...data.data
        );
      } else {
        showServerErrorMsg(data);
      }
    };

    /**
     * 请求可编辑数据
     */
    const getEditable = async () => {
      localLoading.value = true;
      const { data } = await getKycCommonTypeParams.value.getContactEditableApi(
        {
          id: props.id,
        }
      );
      localLoading.value = false;
      if (data.code === 0) {
        formData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (props.updateSection === "identification") {
            updateContactIdentificationInfo();
          } else {
            updateContactAdditionalInfo();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateContactIdentificationInfo = async () => {
      localLoading.value = true;
      const { data } =
        await getKycCommonTypeParams.value.updateContactIdentificationApi(
          formData.value
        );
      localLoading.value = false;
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateContactAdditionalInfo = async () => {
      localLoading.value = true;
      const { data } =
        await getKycCommonTypeParams.value.updateContactAdditionalApi(
          formData.value
        );
      localLoading.value = false;
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const modalShow = () => {
      showModal(modalRef.value);
    };

    const modalHide = () => {
      hideModal(modalRef.value);
    };

    const resetFields = () => {
      if (formRef.value) {
        formRef.value.resetFields();
      }
    };

    onMounted(() => {
      modalShowListener(modalRef.value, () => {
        setTimeout(() => {
          if (props.type === KycType.Natural) {
            getAreaCodesData();
          }
          if (isEditModal.value) {
            getEditable();
          }
        }, 0);
      });
      modalHideListener(modalRef.value, () => {
        resetFields();
      });
    });

    defineExpose({
      modalShow,
      modalHide,
    });

    return {
      localLoading,
      t,
      modalRef,
      formRef,
      isEditModal,
      formData,
      rules,
      KycType,
      areaCodesLoading,
      areaCodesOptions,
      submit,
      modalShow,
      modalHide,
    };
  },
});
