
import {
  defineComponent,
  ref,
  PropType,
  onMounted,
  computed,
  defineExpose,
  reactive,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import { NumberOrString } from "@/core/directive/type/common";
import mixin from "@/mixins";
import _ from "lodash";
import { KycType } from "@/core/directive/type/kyc";
import { inventoryFormData } from "@/core/directive/interface/kyc";
import { ApiKyc } from "@/core/api";
import KycAddress from "@/components/page/kyc/common/Address.vue";
import AdditionalSection from "@/components/page/kyc/common/AdditionalSection.vue";

export default defineComponent({
  name: "inventory-edit-modal",
  components: {
    KycAddress,
    AdditionalSection,
  },
  props: {
    id: {
      type: Number as PropType<NumberOrString>,
      default: 0,
    },
    updateSection: {
      type: String,
      default: "identification", // identification | additional
    },
    type: {
      type: String as PropType<KycType>,
      default: KycType.Juridical,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-info"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const localLoading = ref(false);
    const modalRef = ref<null | HTMLElement>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const {
      showValidateErrorMsg,
      showServerErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const settlementEntityId = route.params.id;
    const isEditModal = computed(() => {
      return props.id !== 0;
    });

    const formData = ref(Object.assign({}, _.cloneDeep(inventoryFormData)));
    const rules = reactive({
      identification: {
        firstname: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        lastname: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        address1: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        address2: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        postcode: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        city_id: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        country_code: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: false,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        district_id: [
          {
            required: false,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
      },
    });

    watch(
      () => formData.value.identification.country_code,
      (newVal) => {
        if (newVal == "CN") {
          rules.identification.province_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        } else if (newVal == "HK" || newVal == "MO") {
          rules.identification.province_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        } else if (newVal == "TW") {
          rules.identification.province_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        }
      }
    );

    /**
     * 请求可编辑数据
     */
    const getEditable = async () => {
      localLoading.value = true;
      const { data } = await ApiKyc.getInventoryEditable({
        id: props.id,
      });
      localLoading.value = false;
      if (data.code === 0) {
        formData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (isEditModal.value) {
            if (props.updateSection === "identification") {
              updateInventoryIdentificationInfo();
            } else {
              updateInventoryAdditionalInfo();
            }
          } else {
            addInventoryInfo();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addInventoryInfo = async () => {
      const { data } = await ApiKyc.addInventory({
        settlement_entity_id: settlementEntityId,
        ...formData.value,
      });
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateInventoryIdentificationInfo = async () => {
      localLoading.value = true;
      const { data } = await ApiKyc.updateInventoryIdentification(
        formData.value
      );
      localLoading.value = false;
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateInventoryAdditionalInfo = async () => {
      localLoading.value = true;
      const { data } = await ApiKyc.updateInventoryAdditional(formData.value);
      localLoading.value = false;
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const modalShow = () => {
      showModal(modalRef.value);
    };

    const modalHide = () => {
      hideModal(modalRef.value);
    };

    const resetFields = () => {
      if (formRef.value) {
        formRef.value.resetFields();
      }
    };

    onMounted(() => {
      modalShowListener(modalRef.value, () => {
        setTimeout(() => {
          if (isEditModal.value) {
            getEditable();
          }
        }, 0);
      });
      modalHideListener(modalRef.value, () => {
        resetFields();
      });
    });

    defineExpose({
      modalShow,
      modalHide,
    });

    return {
      localLoading,
      t,
      modalRef,
      formRef,
      isEditModal,
      formData,
      rules,
      KycType,
      submit,
      modalShow,
      modalHide,
    };
  },
});
