
import {
  defineComponent,
  ref,
  PropType,
  onMounted,
  computed,
  defineExpose,
  reactive,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import { NumberOrString } from "@/core/directive/type/common";
import { TaggingItem } from "@/core/directive/interface/common";
import mixin from "@/mixins";
import _ from "lodash";
import { ApiBase, ApiKyc } from "@/core/api";
import { KycType } from "@/core/directive/type/kyc";
import { legalFormData } from "@/core/directive/interface/kyc";
import { getKycCommonTypeParams } from "@/core/directive/function/kyc";
import KycAddress from "@/components/page/kyc/common/Address.vue";
import AdditionalSection from "@/components/page/kyc/common/AdditionalSection.vue";

export default defineComponent({
  name: "registration-edit-modal",
  components: {
    KycAddress,
    AdditionalSection,
  },
  props: {
    id: {
      type: Number as PropType<NumberOrString>,
      default: 0,
    },
    updateSection: {
      type: String,
      default: "identification", // identification | additional
    },
    type: {
      type: String as PropType<KycType>,
      default: KycType.Juridical,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-info"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const localLoading = ref(false);
    const modalRef = ref<null | HTMLElement>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const {
      showValidateErrorMsg,
      showServerErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const companyLegalFormLoading = ref(false);
    const companyLegalFormOptions = ref<Array<TaggingItem>>([]);

    const settlementEntityId = route.params.id;
    const isEditModal = computed(() => {
      return props.id !== 0;
    });

    const formData = ref(Object.assign({}, _.cloneDeep(legalFormData)));
    const juridicalRequiredField = {
      registered_company_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      company_legal_form: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      registration_authority: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      company_registration_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      legal_representative_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    };
    const naturalRequiredField = {
      firstname: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      passport: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      birthday: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      data_of_issue: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      validity_period: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      id_card: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      id_card_validity_period: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    };
    const rules = reactive({
      identification: {
        address1: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        address2: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        postcode: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        city_id: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        country_code: [
          {
            required: true,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: false,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
        district_id: [
          {
            required: false,
            message: t("common.isRequired"),
            trigger: "change",
          },
        ],
      },
    });

    watch(
      () => props.type,
      (newVal) => {
        if (newVal === KycType.Juridical) {
          Object.assign(rules.identification, juridicalRequiredField);
        } else if (newVal === KycType.Natural) {
          Object.assign(rules.identification, naturalRequiredField);
        }
      },
      { immediate: true }
    );

    watch(
      () => formData.value.identification.country_code,
      (newVal) => {
        if (newVal == "CN") {
          rules.identification.province_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        } else if (newVal == "HK" || newVal == "MO") {
          rules.identification.province_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        } else if (newVal == "TW") {
          rules.identification.province_id = [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
          rules.identification.district_id = [
            {
              required: false,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ];
        }
      }
    );

    const getTaggingData = async () => {
      companyLegalFormLoading.value = true;
      const { data } = await ApiBase.getTaggingData({
        short_key: ["company_legal_form"],
      });
      companyLegalFormLoading.value = false;
      if (data.code == 0) {
        companyLegalFormOptions.value = data.data.company_legal_form.items;
      }
    };

    const querySearch = async (queryString, cb) => {
      const { data } = await ApiKyc.getCompanyLegalFormData({});
      let suggestions;
      if (data.code == 0) {
        suggestions = data.data;
      }
      const results = queryString
        ? suggestions.filter((s) =>
            s.value.toLowerCase().includes(queryString.toLowerCase())
          )
        : suggestions;
      cb(results);
    };

    /**
     * 请求可编辑数据
     */
    const getEditable = async () => {
      localLoading.value = true;
      const { data } =
        await getKycCommonTypeParams.value.getRegistrationEditableApi({
          id: props.id,
        });
      localLoading.value = false;
      if (data.code === 0) {
        formData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (isEditModal.value) {
            if (props.updateSection === "identification") {
              updateLegalIdentificationInfo();
            } else {
              updateLegalAdditionalInfo();
            }
          } else {
            addLegalIdentificationInfo();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addLegalIdentificationInfo = async () => {
      localLoading.value = true;
      const { data } = await getKycCommonTypeParams.value.addRegistrationApi({
        settlement_entity_id: settlementEntityId,
        ...formData.value,
      });
      localLoading.value = false;
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateLegalIdentificationInfo = async () => {
      localLoading.value = true;
      const { data } =
        await getKycCommonTypeParams.value.updateRegistrationIdentificationApi(
          formData.value
        );
      localLoading.value = false;
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateLegalAdditionalInfo = async () => {
      localLoading.value = true;
      const { data } =
        await getKycCommonTypeParams.value.updateRegistrationAdditionalApi(
          formData.value
        );
      localLoading.value = false;
      if (data.code === 0) {
        modalHide();
        showFormSubmitSuccessMsg(() => {
          emit("update-info");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const modalShow = () => {
      showModal(modalRef.value);
    };

    const modalHide = () => {
      hideModal(modalRef.value);
    };

    const resetFields = () => {
      if (formRef.value) {
        formRef.value.resetFields();
      }
    };

    onMounted(() => {
      modalShowListener(modalRef.value, () => {
        setTimeout(() => {
          //getTaggingData();
          if (isEditModal.value) {
            getEditable();
          }
        }, 0);
      });
      modalHideListener(modalRef.value, () => {
        resetFields();
      });
    });

    defineExpose({
      modalShow,
      modalHide,
    });

    return {
      localLoading,
      t,
      modalRef,
      formRef,
      isEditModal,
      formData,
      rules,
      companyLegalFormLoading,
      companyLegalFormOptions,
      KycType,
      submit,
      modalShow,
      modalHide,
      querySearch,
    };
  },
});
