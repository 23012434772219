import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-907fd508"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-12 form-group mb-7" }
const _hoisted_2 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_3 = { class: "col-md-6 form-group mb-7" }
const _hoisted_4 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_5 = { class: "col-md-6 form-group mb-7" }
const _hoisted_6 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_7 = { class: "col-md-6 form-group mb-7" }
const _hoisted_8 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_9 = { class: "col-md-6 form-group mb-7" }
const _hoisted_10 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_11 = { class: "col-md-6 form-group mb-7" }
const _hoisted_12 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_13 = {
  key: 0,
  class: "col-md-6 form-group mb-7"
}
const _hoisted_14 = { class: "fs-6 fw-bold mb-2 required" }
const _hoisted_15 = {
  key: 1,
  class: "col-md-6 form-group mb-7"
}
const _hoisted_16 = { class: "fs-6 fw-bold mb-2 required" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.streetLabel), 1),
      _createVNode(_component_el_form_item, { prop: "identification.address1" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.address1,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.address1) = $event)),
            disabled: _ctx.updateSection === 'additional'
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.houseNoLabel), 1),
      _createVNode(_component_el_form_item, { prop: "identification.address2" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.address2,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.address2) = $event)),
            disabled: _ctx.updateSection === 'additional'
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.postalCodeLabel), 1),
      _createVNode(_component_el_form_item, { prop: "identification.postcode" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.postcode,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.postcode) = $event)),
            disabled: _ctx.updateSection === 'additional'
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.countryLabel), 1),
      _createVNode(_component_el_form_item, { prop: "identification.country_code" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            ref: "MbInputCountryRef",
            class: "w-100",
            modelValue: _ctx.model.country_code,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.country_code) = $event)),
            loading: _ctx.countryLoading,
            placeholder: _ctx.$t('common.plsSelect'),
            clearable: "",
            onChange: _ctx.countryChange,
            filterable: true,
            disabled: _ctx.updateSection === 'additional'
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryOptions, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.value,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "loading", "placeholder", "onChange", "disabled"])
        ]),
        _: 1
      })
    ]),
    (_ctx.country === 'CN' || _ctx.country === 'TW')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.provinceLabel), 1),
            _createVNode(_component_el_form_item, { prop: "identification.province_id" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.model.province_id,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.province_id) = $event)),
                  clearable: "",
                  loading: _ctx.provinceLoading,
                  placeholder: _ctx.$t('common.plsSelect'),
                  autocomplete: "off",
                  filterable: true,
                  disabled: _ctx.updateSection === 'additional',
                  onChange: _ctx.provinceChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provinceOptions, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "loading", "placeholder", "disabled", "onChange"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.cityLabel), 1),
            _createVNode(_component_el_form_item, { prop: "identification.city_id" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.model.city_id,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.city_id) = $event)),
                  clearable: "",
                  loading: _ctx.cityLoading,
                  placeholder: _ctx.$t('common.plsSelect'),
                  autocomplete: "off",
                  filterable: true,
                  disabled: _ctx.updateSection === 'additional',
                  onChange: _ctx.cityChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityOptions, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "loading", "placeholder", "disabled", "onChange"])
              ]),
              _: 1
            })
          ]),
          (_ctx.model.country_code === 'CN')
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.districtLabel), 1),
                _createVNode(_component_el_form_item, { prop: "identification.district_id" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.model.district_id,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.district_id) = $event)),
                      clearable: "",
                      loading: _ctx.districtLoading,
                      placeholder: _ctx.$t('common.plsSelect'),
                      autocomplete: "off",
                      filterable: true,
                      disabled: _ctx.updateSection === 'additional'
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.districtOptions, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "loading", "placeholder", "disabled"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.cityLabel), 1),
          _createVNode(_component_el_form_item, { prop: "identification.city_id" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.model.city_id,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.city_id) = $event)),
                clearable: "",
                filterable: "",
                remote: "",
                loading: _ctx.cityLoading,
                placeholder: _ctx.$t('common.enterKeyword'),
                disabled: _ctx.updateSection === 'additional',
                "remote-method": _ctx.searchCityData
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityOptions, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.value,
                      label: item.label,
                      value: item.value
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "loading", "placeholder", "disabled", "remote-method"])
            ]),
            _: 1
          })
        ]))
  ], 64))
}