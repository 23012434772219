
import { defineComponent, ref, PropType, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { ApiBase } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { useVModel } from "@vueuse/core";
import { KycType } from "@/core/directive/type/kyc";
import mixin from "@/mixins";

export default defineComponent({
  name: "kyc-additional-information",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    sectionTitle: {
      type: String,
      default: () =>
        useI18n().t(
          "kyc.information.contactInformation.additionalSectionTitle"
        ),
    },
    type: {
      type: String as PropType<KycType>,
      default: KycType.Juridical,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const model = useVModel(props, "modelValue", emit);
    const { t } = useI18n();

    const { showServerErrorMsg } = mixin();

    const areaCodesLoading = ref(false);
    const areaCodesOptions = ref<TaggingItem[]>([]);

    const getAreaCodesData = async () => {
      areaCodesLoading.value = true;
      const { data } = await ApiBase.getAreaCodesData({
        max_item: "all",
      });
      areaCodesLoading.value = false;
      if (data.code === 0) {
        areaCodesOptions.value.splice(
          0,
          areaCodesOptions.value.length,
          ...data.data
        );
      } else {
        showServerErrorMsg(data);
      }
    };

    onMounted(() => {
      getAreaCodesData();
    });

    return {
      model,
      t,
      areaCodesLoading,
      areaCodesOptions,
      KycType,
    };
  },
});
